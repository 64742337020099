// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "mock/the-supramental-transformation.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <div>
          <div>
            <h2>Sri Aurobindo And The Mother</h2>
            <h1>THE SUPRAMENTAL TRANSFORMATION</h1>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.img)}
              objectPosition="center top"
            />
          </div>
          <div>
            <div>
              <p>
                TRANSFORMATION
                <br />
                My breath runs in a subtle rhythmic stream
                <br />
                &nbsp;&nbsp;It fills my members with a might divine;
                <br />
                &nbsp;&nbsp;I have drunk the Infinite like a giant’s wine.
                <br />
                Time is my drama and my pageant dream.
                <br />
                Now are my illumined cells joy’s flaming scheme
                <br />
                &nbsp;&nbsp;And changed my thrilled and branching nerves to fine
                <br />
                &nbsp;&nbsp;Channels of rapture opal and hyaline
                <br />
                For the influx of the Unknown and the Supreme.
              </p>
              <p>
                I am no more a vassal of the flesh,
                <br />
                &nbsp;&nbsp;A slave to Nature and her leaden rule;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;I am caught no more in the senses’
                narrow mesh.
                <br />
                My soul unhorizoned widens to measureless sight,
                <br />
                &nbsp;&nbsp;My body is God’s happy living tool,
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;My spirit a vast sun of deathless light.
              </p>
              <p>Sri Aurobindo</p>
            </div>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <div>
            <p className="dropcap">
              <em style={{ fontWeight: 600 }}>
                <span>S</span>ri Aurobindo and Mother came here to bring the
                beginning of the next stage in human evolution. The history of
                humanity is the continual evolution into greater consciousness
                on the way to becoming the All-Consciousness and All-Love. Human
                beings are the most evolved creatures on earth. They are mental
                beings, ruled by the mind and subject to its limitations. Man’s
                mind isn’t capable of encompassing the Supreme All-Consciousness
                and All-Love. Sri Aurobindo calls the next stage “Supramental”
                because man will develop beyond the functions and capacities and
                limitations of the mind. Both Sri Aurobindo and Mother
                experienced this new, powerful, all-containing consciousness
                emerging in themselves. They saw that this consciousness is
                going to emerge in a wide number of people in a powerful forward
                step in human evolution. There will be a rapid and complete
                change compared with the pace of evolution known in human
                history.
              </em>
            </p>
            <p className="text-indent">
              Sri Aurobindo wrote; “To be aware wholly and integrally of oneself
              and of all the truth of one’s being is the necessary condition of
              true possession of existence. This self-awareness is what is meant
              by spiritual knowledge; the essence of spiritual knowledge is an
              intrinsic self-existent consciousness; all its action of
              knowledge, indeed all its action of any kind must be that
              consciousness formulating itself.” This is human destiny. The
              All-Consciousness will now emerge in all of its fullness. It is
              something entirely new for humanity, and therefore it is not
              possible to know in advance everything that will happen. Sri
              Aurobindo and Mother did everything they could to prepare mankind
              for this new and unknown change which will change everything
              because it is the emergence of the Supreme All-Consciousness and
              All-Love into human beings. This is what they call the
              “Supramental Transformation”.
            </p>
            <TableOfContents lang={lang} />
          </div>
          <div>&nbsp;</div>
        </div>
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
